var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.nema.estado !== 'DESPACHO' && _vm.nema.estado !== 'EN RUTA' && _vm.nema.estado !== 'DESPACHADA' && _vm.nema.estado !== 'INSTALACION' && _vm.nema.estado !== 'INSTALADA')?_c('b-button',{attrs:{"block":"","variant":"primary"},on:{"click":_vm.abrirModal}},[_vm._v(" "+_vm._s(_vm.tituloModal())+" ")]):_vm._e(),_c('b-modal',{attrs:{"title":_vm.tituloModal(),"hide-footer":"","no-close-on-backdrop":""},on:{"hidden":_vm.limpiarModal},model:{value:(_vm.verModalPreDiagnostico),callback:function ($$v) {_vm.verModalPreDiagnostico=$$v},expression:"verModalPreDiagnostico"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-overlay',{attrs:{"show":_vm.show}},[(_vm.nema.estado === 'PRUEBAS' || _vm.nema.estado === 'GARANTIA')?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Listado de Pruebas ")]),_c('b-table',{attrs:{"items":_vm.pruebasLampara,"fields":_vm.camposTabla},scopedSlots:_vm._u([{key:"cell(nombre)",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"align-text-top text-uppercase"},[_vm._v(" "+_vm._s(item.nombre)+" ")])]}},{key:"cell(check)",fn:function(ref){
var item = ref.item;
return [_c('b-form-checkbox',{attrs:{"disabled":_vm.checkboxHabilitado},model:{value:(item.checkboxEstado),callback:function ($$v) {_vm.$set(item, "checkboxEstado", $$v)},expression:"item.checkboxEstado"}})]}}],null,false,3640914164),model:{value:(_vm.pruebasSeleccionadas),callback:function ($$v) {_vm.pruebasSeleccionadas=$$v},expression:"pruebasSeleccionadas"}})],1),_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" ¿Pruebas Exitosas? ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"¿Pruebas Exitosas?","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"textoQr qr-textarea",attrs:{"state":errors.length > 0 ? false:null,"label":"nombre","options":_vm.destinosLaboratorio},on:{"input":_vm.deshabilitarCheckbox},model:{value:(_vm.destinoNema),callback:function ($$v) {_vm.destinoNema=$$v},expression:"destinoNema"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2571763469)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Comentarios ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Comentarios Pruebas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{staticClass:"textoQr qr-textarea",attrs:{"no-resize":"","state":errors.length > 0 ? false:null},model:{value:(_vm.comentarios),callback:function ($$v) {_vm.comentarios=$$v},expression:"comentarios"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1826681963)})],1)],1)],1):_vm._e(),(_vm.nema.estado === 'PRUEBAS' || _vm.nema.estado === 'GARANTIA')?_c('b-row',[_c('b-col',{staticStyle:{"display":"flex","justify-content":"end"},attrs:{"cols":"12"}},[_c('b-button',{attrs:{"block":"","variant":"primary"},on:{"click":function($event){return _vm.agregarFoto($event)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Agregar Foto")])],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('table',_vm._l((_vm.fotosTracking),function(foto,index){return _c('tr',{key:index},[_c('td',{staticStyle:{"width":"70%"}},[_c('Imagen',{key:'recepcion-' + index,attrs:{"leyenda":'recepcion-' + index},on:{"cargar-file":function($event){return _vm.imagenTracking(index, $event)}}})],1),_c('td',[_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.eliminarFoto()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Eliminar")])],1)],1)])}),0)])],1)],1):_vm._e(),_c('hr'),_c('b-row',[_c('b-col',{staticStyle:{"display":"flex","justify-content":"end"},attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.validationForm}},[_vm._v(" Guardar Diagnóstico ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }